import axios from 'axios'

import catMap from '../../lib/catMap'
import React, { useEffect, useState } from 'react'

import CalculationActionButton from './CalculationActionButton'

import { useNavigate } from 'react-router-dom'
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { ReactComponent as ClockIcon } from '../../assets/Clock.svg'
import { ReactComponent as ClimateHoundBadge } from '../../assets/climatehoundbadge.svg'
import { ReactComponent as ClimateHoundPartnerSeal } from '../../assets/ClimateHound_PartnerSeal.svg'

import classnames from 'classnames'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import LoopIcon from '@mui/icons-material/Loop';
import MobiledataOffIcon from '@mui/icons-material/MobiledataOff';
import HomeDashboardChart from './HomeDashboardChart'
import processing from "../../assets/Processing.gif"
import Tooltip from '../Tooltip'
import ProgressBarWithLabel from './ProgressBarWithLabel';

export default function Insights(props) {
  const navigate = useNavigate()

  const [goneNeutral, setGoneNeutral] = useState(true)
  const [user, setUser] = useState({})
  const [statBlocks, setStatBlocks] = useState([])
  const [reportsLoaded, setReportsLoaded] = useState(false)

  const defaultComparisons = {
    cars: 0.0,
    per_100k: 0.0,
    per_unit_produced: 0.0,
  }

  const [ topEmittingCategories, setTopEmittingCategories ] = useState([])
  const [comparisons, setComparisons] = useState(defaultComparisons)
  const [total, setTotal] = useState(0.0)
  const [totalCostCents, setTotalCostCents] = useState(0)
  const [bySection, setBySection] = useState([])
  const [ strats, setStrats ] = useState([])


  function smallOrLargeNumber(num) {
    if (typeof num === 'number') {
      if (num === 0) {
        // return an integer and never 0.0
        return 0
      } else {
        if (Math.abs(num) >= 1.0) {
          // it's a big number, neg or pos
          // let's display it to fixed 1 decimal point
          // unless it has no decimal place
          if (Math.abs(num) % 1 === 0) {
            // no decimal part
            return num.toFixed(0)
          } else {
            // just display the tenths place
            return num.toFixed(1)
          }
        } else {
          return num.toPrecision(2)
        }
      }
    } else {
      return '...'
    }
  }

  const [companyName, setCompanyName] = useState('')

  useEffect(() => {
    axios.get('/api/reports/current/summary').then((res) => {
      setBySection(res.data.emissions_by_section)
      setComparisons(res.data.comparisons)
      setTotal(res.data.total_kgco2e)
      setTopEmittingCategories(res.data.top_emitting_categories)
      setReportsLoaded(true)
      const tonnesRoundedUp = Math.ceil(res.data.total_kgco2e * 0.001)
      const costPerTonneCents = 1200
      setTotalCostCents(tonnesRoundedUp * costPerTonneCents)
    })

    axios.get('/api/users/me').then((res) => {
      setCompanyName(res.data['company'])
      setGoneNeutral(res.data['gone_neutral'])
      setUser(res.data)
    })

    axios.get('/api/reduction_strategies/for_user').then((res) => {
      const totalStrats = res.data.reduction_strategies;
      setStrats((totalStrats))
    })

    axios.get('/api/metrics/').then((res) => {
      const totalStats = res.data
      setStatBlocks((totalStats))
    })
    
  }, [])

  const loading = !reportsLoaded || !companyName.length || !strats.length

  const centsToDollars = (cents) => {
    const dollars = cents * 0.01
    return `$${dollars}.00`
  }

  const openPaymentPage = () => {
    // window.open('https://stripe.com', '_blank')
    axios
      .post('/api/payments/report_redirect', { offset_cents: totalCostCents })
      .then((res) => {
        window.location = res.data['checkout_url']
      })
  }

  const goToDash = () => {
    navigate('/insights/details')
  }
  const goToPay = () => {
    navigate('/insights/take-action')
  }

  const startNextCalc = () => {
    axios.post(`/api/users/${user.id}/start_next_calculation`).then((res) => {
      window.location = '/questionnaire/start'
    })
  }
  
  const selectCats = [
    'distribution-and-travel',
    'energy-usage',
    'waste-management',
    'procurement',
  ]

  const statsDom = selectCats.map((catSlug) => {
    const results = bySection.find((s) => s.section_slug === catSlug)
    if (!results) {
      return
    }
    const cat = catMap[catSlug]
    const Icon = cat.icon
    return (
      <div key={cat.name} className='stat'>
        <header>
          <Icon />
          <h5>{cat.name}</h5>
        </header>
        <figure>
          <h2>{smallOrLargeNumber(results.total_kgco2e * 0.001)}</h2>
          <label className='body4'>MTCO2e</label>
        </figure>
      </div>
    )
  })

  const InitiativeBlock = ({name, id, slug}) => (
    <div className="initiativeRow" key={id}>
      <div>
        <h5>{name}</h5>
        <div className="initiativeInfoHolder">
          <span>
            Just added
          </span>
          <span>
            {slug === 'pv-panels' ? "Utilities" : "Transportation"}
          </span>
        </div>
      </div>
      <span className="exploreLink body4" onClick={() => navigate('/emissions-planning?slug=' + slug)}>
        Explore
      </span>
    </div>
  )

  const textToShowIfActionRequired = (slug) => {
    if(slug === "weekly-electricity") {
      return "Log into your electricity during your next carbon footprint"
    } else if(slug === "weekly-natural-gas") {
      return "Log into your natural gas during your next carbon footprint"
    } else {
      return "Complete your carbon footprint to see this metric"
    }
  }

  const metricChangeIsGood = (delta, slug) => {
    if(!delta) {
      return false
    }
    const goodToGoDownMetrics = [ 'weekly-electricity', 'weekly-natural-gas', 'total-emissions' ]
    const goodToGoUpMetrics = [ 'waste-diversion' ]
    if (goodToGoUpMetrics.indexOf(slug) > -1) {
      console.log('is it good')
      return delta > 0
    }
    if (goodToGoDownMetrics.indexOf(slug) > -1) {
      return delta < 0
    }
  }

  const displayArrow = (delta, slug) => {
    if(!delta) {
      return <MobiledataOffIcon />
    }
    if(delta === 0) {
      return <LoopIcon />
    }
    if(delta > 0) {
      return <ArrowUpwardOutlinedIcon />
    } else {
      return <ArrowDownwardOutlinedIcon/>
    }
  }

  const normalizeStat = (amount) => {
    // if it's a string or something, just display it
    if (typeof amount !== 'number') {
      return amount
    }
    // if it's a number, and it's 0 or 0.0, display 0
    if (amount === 0) {
      return '-'
    }
    if (Number.isInteger(amount)) {
      // if it's an int, we can just add commas
      return amount.toLocaleString()
    } else {
      // if it's a float, we can put it in our decimal decorator
      return smallOrLargeNumber(amount).toLocaleString()
    }
  }

  const HomeStatBlock = ({title, amount, label, delta, status, slug}) => {
    const hideStat = status === 'action_required' && ((slug !== "waste-diversion") && (slug !== "total-emissions"))
    return (
    <div className="statContainer">
      <div className={classnames('stat', {hideStat})}>
        <header>
          <h5>{title} {(slug === 'waste-diversion') && <Tooltip>Percentage of total waste diverted from the landfill to recycling, compost, animal feed, or anaerobic digestion.</Tooltip>} </h5>
        </header>
        <div className="statBody">
          <figure className="mainStat">
            <h4>{normalizeStat(amount)}{ label === "Rate" && "%" }</h4>
            <label className='micro' >{label}</label>
          </figure>
          <figure className="pillText">
            <div className={classnames("micro", {pillStatGood: metricChangeIsGood(delta, slug)})}>
              {displayArrow(delta, slug)}
              <span>{delta ? `${smallOrLargeNumber(delta)}%` : `-- %`}</span>
              </div>
            <label className='micro' >vs. last year</label>
          </figure>
        </div>
      </div>
      {hideStat && <div className="hiddenStatText micro">{textToShowIfActionRequired(slug)}</div>}
    </div>
  )}

  const vendorInfo = topEmittingCategories?.length ? topEmittingCategories : [
    {name: 'Wastewater services', value: '787'}, 
    {name: 'Heavy machinery', value: '776'},
    {name: 'Glass manufacturing', value: '765'},
    {name: 'Petroleum industry', value: '754'},
    {name: 'Non-durable goods', value: '743'},
  ]

  const hideVendors = !user.most_recent_calc_year || !topEmittingCategories.length
  const hideEmissions = !user.emissions_estimates
  const hidePolicies = !user.most_recent_calc_year
  const userIsNeutral = user.gone_neutral

  const climateHoundBadges = (
    <div className={classnames('userMetrics badgeMetric', {hiddenNeutrality: userIsNeutral || !user.report_available})}>
      <div className={classnames("userMetricBadges partnerBadge", {hiddenUserBadge: !user.report_available})}>
        <span>
          <div className="lockedBadge">
            <ClimateHoundPartnerSeal />
          </div>
          {!user.report_available && <span className="lockedBadgeIcon"><LockOutlinedIcon /></span>}
        </span>
        {user.report_available && <div className="micro">since {user.partner_since_year}</div>}
      </div>
      <div className={classnames("userMetricBadges", {hiddenUserBadge: !userIsNeutral})}>
        <span>
          <div className="lockedBadge">
            <ClimateHoundBadge/>
          </div>
          {!userIsNeutral && <span className="lockedBadgeIcon"><LockOutlinedIcon /></span>}
        </span>
        {userIsNeutral && <div className="micro">since {user.neutral_since_year}</div>}
      </div>
    </div>
  )

  const carbonNeutralTab = (
    <div className='userMetrics carbonNeutral' >
      <div className="userMetricMainIcon">
        <WbSunnyIcon />
        <div className="userMetricMainText">
          <div className="body3">
            Go carbon neutral
          </div>
          <div className='micro'>
            Get certified today!
          </div>
        </div>
      </div>
      <div className="userMetricArrow">
        <ArrowCircleRightOutlinedIcon onClick={goToPay} />
      </div>
    </div>
  )

  const featureCompletionTab = (
    <div className='userMetrics featureCompletionMetric' >
      <div className="userMetricMainIcon">
        <ChecklistIcon />
        <div className="userMetricMainText">
          <div className="body3">
            Feature usage
          </div>
          <div className='micro'>
            <div>
              <ProgressBarWithLabel variant="determinate" value={user.feature_completion_percentage} />
            </div>
          </div>
        </div>
      </div>
      <div className="userMetricArrow">
        <ArrowCircleRightOutlinedIcon onClick={() => null} />
      </div>
    </div>
  )

  if(loading) {
    return (
      <div id='insights' className="loaderGif">
        <img src={processing} />
      </div>
    )
  }

  return (
    <div className='page' id='insights'>
      <header className='welcome'>
        <div className='welcomeText'>
          <h3>
          Welcome, {companyName}!
          </h3>
        </div>
      </header>
      <div className="userMetricRow">
        {climateHoundBadges}
        {(user && !userIsNeutral && user.report_available) && carbonNeutralTab}
        {featureCompletionTab}
        <CalculationActionButton user={user} />
      </div>

      <div className='statsBlock homeGreen'>
        {statBlocks.map( statBlock => <HomeStatBlock {...statBlock} />)}
      </div>

      <div className={classnames("dashboardChartHolder", {hideEmissions})}>
        <div className={classnames("dashboardChartv2", {hideEmissions})}>
          <h4>Emissions Estimate <Tooltip>Trailing estimate for the time frame selected based on your most recent carbon footprint and ongoing bookkeeping data.</Tooltip> </h4>
          <HomeDashboardChart user={user} /> 
        </div>
        {hideEmissions && <h5>Log into your bookkeeping during your first carbon footprint for an ongoing emissions estimate</h5>}
      </div>

      <div className='strategiesAndPolicies'>
        <div className="vendorContainer">
          <div className={classnames('vendors', {hideVendors})}>
            <div className='header'>
              <h3>Emission-Intensive Vendors <Tooltip>Vendor categories with the highest emissions per $1,000 spent according to the EPA based on your carbon footprint's bookkeeping data.</Tooltip></h3>
            </div>
            <div>
              <div className="body4">
                <span>Vendor Category</span>
                <span>kgCO2e/$1,000</span>
              </div>
              <div>
                {vendorInfo.map((vendor, idx) => (
                  <div className="body3">
                    <span className="vendorTitle">{idx + 1}. {vendor.name}</span>
                    <span>{vendor.total_co2e} kgCO2e</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        
        <div className={classnames('policies', {hidePolicies})}>
          <div className='header'>
            <h3>ClimateHound Initiatives</h3>
            <input type="button" className="skeleton" value="View all" onClick={() => navigate('/emissions-planning')} />
          </div>
          <div>
            {strats.slice(0, 3).map( strat => <InitiativeBlock name={strat.name} id={strat.id} key={strat.id} slug={strat.slug} />)}
          </div>
        </div>
      </div>
    </div>
  )
}
